import { ThemeOptions } from "@mui/material";

const themeData: ThemeOptions = {
    shape: {
        borderRadius: 30,
    },
    palette: {
        primary: {
            main: '#FFAC48',
        },
        secondary: {
            main: "#dd413e",
        }
    },
    typography: {
        // fontWeightRegular: 500,
        // fontWeightMedium: 700,
        // fontWeightBold: 900,
        fontFamily: '"Ubuntu", sans-serif',
        button: {
            textTransform: "none",
            fontWeight: 500,
            borderRadius: 50
        },
        h1: {
            fontWeight: 700
        },
        h2: {
            fontWeight: 700
        },
        h3: {
            fontWeight: 700
        },
        h4: {
            fontWeight: 500
        },
        h5: {
            fontWeight: 500
        },
    },
}

export default themeData;