import React from 'react'
import './Approach.css'
import curve from '../../../../assets/images/yellow_wave.svg';
import { Avatar, Card, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import { useWindowSize } from '../../../../hooks/useWindowSize';
function Approach() {

    const windowSize = useWindowSize();
    const [booped, setBooped] = React.useState(-1);

    interface Card {
        title: string;
        text: string;
        bg: any;
        chara: any;
    }

    const cards: Card[] = [
        {
            title: "Project Deep Dive",
            text: "Our team of developers, designers and artists work from within the engine of the game to understand and optimize what makes the mechanic engaging.",
            bg: require('../../../../assets/images/approach/deepdive_BG.png'),
            chara: require('../../../../assets/images/approach/deepdive_chara.png'),
        },
        {
            title: "Ideate",
            text: "Leveraging our years of development and marketing experience, we use our Mechanical Matrix approach to isolate high impact ad concepts.",
            bg: require('../../../../assets/images/approach/ideate_BG.png'),
            chara: require('../../../../assets/images/approach/ideate_chara.png'),
        },
        {
            title: "Create",
            text: "Our team then gets to work optimizing and creating the code and art assets necessary to generate clear and compelling creatives.",
            bg: require('../../../../assets/images/approach/create_BG.png'),
            chara: require('../../../../assets/images/approach/create_chara.png'),
        },
        {
            title: "Collaborative Iteration",
            text: "Working alongside your Marketing teams, our approach contributes to a long term creative strategy capable of producing new winning concepts.",
            bg: require('../../../../assets/images/approach/collab_BG.png'),
            chara: require('../../../../assets/images/approach/collab_chara.png'),
        },
    ]

    //1920 => width
    //300 => X
    const height = (300 * windowSize.width) / 1920;
    const margin = -height / 2;

    const spacerStyle: React.CSSProperties = {
        backgroundImage: `url(${curve})`,
        aspectRatio: "1920/300",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        // marginBottom: 
    }

    const mobile = windowSize.width < 900;
    return (
        <>
            <div className='Approach'>
                {/* <Grid container>
                    <Grid item xs />
                    <Grid item xs={12} sm={9} lg={8} spacing={3} padding={10}>
                        <Typography variant="h3">
                            
                        </Typography>
                    </Grid>
                    <Grid item xs />
                </Grid> */}
                <Typography variant="h3" className='WhiteText'>
                    Our Approach
                </Typography>
                <Grid container style={windowSize.width >= 900 ? { height: "100%" } : { height: "100%" }}>
                    <Grid item xs />
                    <Grid container item xs={11} sm={10} md={11} lg={10} margin={mobile ? 0 : 2} marginTop={2} columnSpacing={mobile ? 0 : 3} rowSpacing={3}>
                        {
                            cards.map((card, iCard) => {
                                const isBooped = booped === iCard;
                                return (
                                    <Grid key={iCard} item xs={12} md={3}>
                                        <div className='CustomCard' onMouseLeave={() => { if (isBooped) { setBooped(-1) } }} onMouseEnter={() => { setBooped(iCard) }} style={{ overflow: "clip", minHeight: windowSize.width >= 900 ? "100%" : "1px" }} >
                                            <Typography
                                                variant="h3"
                                                className='DecoratedText'
                                                sx={{
                                                    fontSize: 36,
                                                    fontWeight: 900,
                                                    textShadow: "0px 3px black",
                                                    position: "relative",
                                                    width: "1.4em",
                                                    textAlign: "center",
                                                    height: "1.4em",
                                                    marginTop: "5px",
                                                    marginBottom: "-1.6em",
                                                    zIndex: 2,
                                                    backgroundColor: "white",
                                                    borderRadius: 50,
                                                    left: "calc(90% - 0.75em)",
                                                    // top: "0.75em",
                                                    // bottom: 30,
                                                    // left: 20
                                                }}>
                                                {iCard + 1}
                                            </Typography>
                                            <div style={{
                                                width: "101%",
                                                margin: -1,
                                                transform: isBooped ? "scale(1.1)" : "scale(1)",
                                                transition: `transform ${200}ms`,
                                                aspectRatio: "16 / 9",
                                                backgroundImage: `url(${card.bg})`,
                                                backgroundSize: "cover",
                                                overflow: "hidden"
                                            }}>
                                                <img
                                                    alt={card.title}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        transform: isBooped ? "scale(1.1)" : "scale(1)",
                                                        transition: `transform ${200}ms`,
                                                    }}
                                                    src={card.chara}
                                                />
                                            </div>
                                            <div style={{ padding: "10px", overflow: "clip" }}>
                                                {/* <CardHeader avatar={ } /> */}
                                                <CardContent>
                                                    {/* <Avatar variant='square' sx={{ height: 70, width: 70, marginBottom: 1 }} /> */}
                                                    <Typography variant="h5">{card.title}</Typography>
                                                    <Typography>
                                                        {card.text}
                                                    </Typography>
                                                </CardContent>
                                                {/* <Typography>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente facilis voluptatibus soluta, illo beatae enim sed sunt id tenetur facere, quidem vero, recusandae magnam voluptas tempora totam. Unde, doloremque ducimus?
                                                </Typography> */}
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs />
                </Grid>
            </div>
            <div style={spacerStyle} />
        </>
    )
}

export default Approach