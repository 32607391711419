import React from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './pages/Main/Main';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import themeData from './theme/theme'

const theme = createTheme(themeData);

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </>
  );
}

export default App;
