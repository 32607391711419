import { Box, Grid, Paper, Typography, useTheme } from '@mui/material'
import React from 'react'
import './About.css'
import img from '../../../../assets/images/illustrations/paginarender02.png';

function About() {
    const theme = useTheme();
    return (
        <div className='About'>
            {/* <Paper className='CustomPaper' sx={{ borderRadius: 0, zIndex: 1000, marginBottom: 1 }} > */}
            <Grid container>
                <Grid item xs />
                <Grid container item xs={12} sm={9} lg={8} spacing={3} padding={2} margin={2}>
                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography paragraph variant="h3">About Us</Typography>
                        <Typography paragraph>Our team of gamers, developers, artists and marketers has over a decade of experience in designing and distributing engaging content. We know your customers because we ARE your customers.</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <img alt="image" src={img} style={{ width: "100%" }} />
                    </Grid>
                </Grid>
                <Grid item xs />
            </Grid>
            {/* <Typography variant="h2">
                    Unlock the <span className='DecoratedText'>full</span> potential
                </Typography>
                <Typography variant="h2">
                    of your games
                </Typography>
                <img alt='image' style={{ maxWidth: "55vw" }} src={img} /> */}
            {/* </Paper> */}
        </div>
    )
}

export default About