import React from 'react';
import { useTheme } from '@mui/material';
import * as MuiAppBar from '@mui/material/AppBar';
import { alpha, styled } from '@mui/material/styles';

const AppBar = styled(MuiAppBar.default)<MuiAppBar.AppBarProps>(({ theme }) => ({
    backgroundColor: "white",
    borderBottom: "5px black solid"

}))

export default AppBar