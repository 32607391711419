import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, FormLabel, Grid, Input, InputBase, Paper, Slide, TextField, Typography, useTheme } from '@mui/material'
import React, { LegacyRef, useRef, useState } from 'react'
import './Contact.css'
import img from '../../../../assets/images/illustrations/paginarender03.png';
import emailjs from '@emailjs/browser';
import Loading from 'react-loading';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Contact() {
    const theme = useTheme();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const emailParts = email.split('@');
    const emailDomain = emailParts[1];

    const domainParts = emailDomain?.split('.');

    const validEmail = email && emailParts[0] && emailDomain && domainParts && domainParts[1];

    // const toSend: HTMLFormElement = {
    //     user_email: email,
    //     user_name: name,
    //     message: "let's get in contact!",
    // };

    const emailForm = useRef<HTMLFormElement>();

    const onSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true);
        emailjs.sendForm(
            'service_hkqcp38',
            'template_kqxusf7',
            emailForm.current!,
            'JqS1fmQsdV-6o7Mcy'
        )
            .then((response) => {
                setLoading(false);
                console.log('SUCCESS!', response.status, response.text);
                setSuccessDialogOpen(true);
            })
            .catch((err) => {
                setLoading(false);
                console.log('FAILED...', err);
                setFailText(err.text);
                setFailDialogOpen(true);
            });
    };

    const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
    const [failDialogOpen, setFailDialogOpen] = React.useState(false);
    const [failText, setFailText] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    return (
        <div className='Contact'>
            <Grid container>
                <Grid item xs />
                <Grid container item xs={12} sm={9} lg={8} spacing={3} padding={2} margin={2}>
                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <img alt="image" src={img} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography variant="h5" style={{ color: theme.palette.grey[600] }}>Want to know more?</Typography>
                        <Typography paragraph variant="h3">Let's get in touch!</Typography>
                        <form onSubmit={onSubmit} ref={emailForm as LegacyRef<HTMLFormElement>} id="email-form" style={{ display: "flex", flexDirection: "column" }}>
                            <label><Typography> Name & Company</Typography></label>
                            <input type="text" name="user_name" placeholder="Martin from Pizia" value={name} onChange={(e) => { setName(e.target.value) }} className='CustomInput' style={{ marginBottom: 10, padding: 5, paddingLeft: 25, height: 30, borderRadius: 50 }} />
                            <label><Typography> Email</Typography></label>
                            <input type="email" name="user_email" placeholder="contact@pizia.com" value={email} onChange={(e) => { setEmail(e.target.value) }} className='CustomInput' style={{ marginBottom: 10, padding: 5, paddingLeft: 25, height: 30, borderRadius: 50 }} />
                            <input type="submit" value="Send" disabled={!name || !validEmail} className='CustomButton' style={{ height: 50, borderRadius: 50, width: 150 }} />
                        </form>
                        {/* <FormGroup ref={emailForm} id="email-form">
                            <FormLabel style={{ fontWeight: "bold" }}>Name & Company</FormLabel>
                            <InputBase type="text" name="user_name" placeholder='Martin from Pizia' inputProps={{ 'aria-label': 'search google maps' }} value={name} onChange={(event) => { setName(event.target.value) }} disabled={false} sx={{ p: 1, pl: 3, backgroundColor: "white", boxShadow: "0 3px black", border: "2px solid black", borderRadius: 50 }} />
                            <div style={{ height: 10 }} />
                            <FormLabel style={{ fontWeight: "bold" }}>Email</FormLabel>
                            <InputBase type="email" name="user_email" placeholder='martin@pizia.com' inputProps={{ 'aria-label': 'search google maps' }} value={email} onChange={(event) => { setEmail(event.target.value) }} disabled={false} sx={{ p: 1, pl: 3, backgroundColor: "white", boxShadow: "0 3px black", border: "2px solid black", borderRadius: 50 }} />
                            <div style={{ height: 10 }} />
                            <Button onClick={onSubmit} disabled={!name || !validEmail} sx={{ width: 150, borderRadius: 50, border: "2px solid black", boxShadow: "0px 3px black", ":hover": { boxShadow: "0px 3px black", textDecoration: "2px underline black" } }} disableElevation variant="contained" size="large" className='MainButton'>Send!</Button>
                        </FormGroup> */}
                    </Grid>
                </Grid>
                <Grid item xs />
            </Grid>
            <Dialog TransitionComponent={Transition} PaperProps={{ style: { border: "2px solid black", boxShadow: "0 3px black" }, elevation: 0 }} open={successDialogOpen} fullWidth maxWidth="xs" onClose={() => { setSuccessDialogOpen(false) }}>
                <DialogTitle>
                    Contact Request sent successfully!
                </DialogTitle>
                <DialogContent>
                    <p> Thank you for your interest</p>
                    <p> We'll get in touch soon!</p>
                </DialogContent>
                <DialogActions>
                    <button className='CustomButton' onClick={() => { setSuccessDialogOpen(false) }} style={{ height: 50, borderRadius: 50, width: 100 }} >
                        OK
                    </button>
                </DialogActions>
            </Dialog>
            <Dialog TransitionComponent={Transition} PaperProps={{ style: { border: "2px solid black", boxShadow: "0 3px black" }, elevation: 0 }} open={failDialogOpen} fullWidth maxWidth="xs" onClose={() => { setFailDialogOpen(false) }}>
                <DialogContent>
                    <p>Sorry! Looks like there was a mistake on our end...</p>
                    <p>Error Message: {failText}</p>
                </DialogContent>
                <DialogActions>
                    <button className='CustomButton' onClick={() => { setFailDialogOpen(false) }} style={{ height: 50, borderRadius: 50, width: 100 }} >
                        OK
                    </button>
                </DialogActions>
            </Dialog>
            <Dialog PaperProps={{ style: { background: "none" }, elevation: 0 }} open={loading} >
                <DialogContent>
                    <Loading type='bubbles' />
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default Contact