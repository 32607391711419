import React from 'react'
import './Services.css'
import curve from '../../../../assets/images/yellow_wave.svg';
import { Avatar, Card, CardActionArea, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Services() {

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogService, setDialogService] = React.useState(0);

    const [booped, setBooped] = React.useState(-1);

    interface Card {
        title: string;
        icon: any;
    }

    const cards: Card[] = [
        {
            title: "Platform & Brand Safe",
            icon: require('../../../../assets/images/icons/Platform.png'),
        },
        {
            title: "2D Assets",
            icon: require('../../../../assets/images/icons/2dAssets.png'),
        },
        {
            title: "3D Assets",
            icon: require('../../../../assets/images/icons/3dAssets.png'),
        },
        {
            title: "Animations",
            icon: require('../../../../assets/images/icons/Animations.png'),
        },
        {
            title: "Interstitials",
            icon: require('../../../../assets/images/icons/Interstitials.png'),
        },
        {
            title: "Video Ads",
            icon: require('../../../../assets/images/icons/Video.png'),
        },
        {
            title: "Playables",
            icon: require('../../../../assets/images/icons/Playables.png'),
        },
        {
            title: "Editing & Post Production",
            icon: require('../../../../assets/images/icons/Editing.png'),
        },
    ]

    return (
        <>
            <div className='Services'>
                <Typography variant="h3">
                    We Offer...
                </Typography>
                <Grid container>
                    <Grid item xs />
                    <Grid container item xs={12} sm={9} lg={8} spacing={3} padding={2} margin={2}>
                        {
                            cards.map((card, iCard) => {
                                const isBooped = booped === iCard;
                                return (
                                    <Grid key={iCard} item xs={6} md={3}>
                                        <Card onMouseLeave={() => { if (isBooped) { setBooped(-1) } }} onMouseEnter={() => { setBooped(iCard) }} elevation={0} style={{ borderRadius: 25, border: "3px solid black", boxShadow: "0px 6px black", aspectRatio: "9/10" }}>
                                            {/* <CardActionArea
                                                style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", textAlign: "center", height: "100%" }}
                                                onClick={
                                                    () => {
                                                        setDialogOpen(true);
                                                        setDialogService(iCard)
                                                    }
                                                }
                                            > */}
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", textAlign: "center", height: "100%" }}>

                                                <img alt="" src={card.icon} style={{
                                                    maxHeight: "50%",
                                                    marginBottom: 20,
                                                    transform: isBooped ? "scale(1.15)" : "scale(1)",
                                                    transition: `transform ${200}ms`,
                                                }} />
                                                <Typography variant="h5" sx={{ fontSize: "130%" }} >{card.title}</Typography>
                                            </div>
                                            {/* </CardActionArea> */}
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs />
                </Grid>
            </div>
            {/* <Dialog TransitionComponent={Transition} PaperProps={{ style: { border: "2px solid black", boxShadow: "0 3px black" }, elevation: 0 }} open={dialogOpen} fullWidth maxWidth="xs" onClose={() => { setDialogOpen(false) }}>
                <DialogTitle sx={{ display: "flex" }}>
                    <Avatar variant="square" typeof='img' src={cards[dialogService].icon} />
                    {cards[dialogService].title}
                    <span style={{ flexGrow: 1 }} />
                    <IconButton onClick={() => { setDialogOpen(false) }} style={{ aspectRatio: "1 / 1" }}>
                        X
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <p> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio amet dignissimos, repellendus earum delectus dicta ullam atque facilis pariatur et ex non ut excepturi aperiam provident quasi praesentium sit similique?</p>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt reprehenderit quos quam aliquam eveniet earum praesentium ullam! Fugit eos ad cum voluptas quaerat neque animi voluptatem est laboriosam qui. Delectus.</p>
                </DialogContent>
            </Dialog> */}
        </>
    )
}

export default Services