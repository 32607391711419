import React from 'react'
import './Testimonials.css'
import curve from '../../../../assets/images/yellow_wave.svg';
import { Avatar, Card, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import { useWindowSize } from '../../../../hooks/useWindowSize';
function Testimonials() {

    const windowSize = useWindowSize();
    const [booped, setBooped] = React.useState(-1);

    interface Card {
        headshot: any;
        logo: any;
        text: string;
        name: string;
    }

    const cards: Card[] = [
        {
            text: "\"It's been fantastic to work with Pizia and we will continue partnering to power our growth. They have the best attributes you'd want in a partner: they are proactive with compelling ideas and quick execution... and the results are effective.\"",
            name: "Michael Martinez, CEO & Founder, FunCraft",
            headshot: require('../../../../assets/images/testimonials/funcraft-headshot.jpg'),
            logo: require('../../../../assets/images/testimonials/funcraft-logo.png'),
        },
        {
            text: "\"It's been great to work with Pizia. They've handled the brainstorming, recording and editing of the creatives and their top creative has not yet been beaten!\"",
            name: "Jorge Lorenzon, CEO & Founder, Buldogo Games",
            headshot: require('../../../../assets/images/testimonials/buldogo-headshot.jpeg'),
            logo: require('../../../../assets/images/testimonials/buldogo-logo.png'),
        },
    ]

    //1920 => width
    //300 => X
    const height = (300 * windowSize.width) / 1920;
    const margin = -height / 2;

    const spacerStyle: React.CSSProperties = {
        backgroundImage: `url(${curve})`,
        aspectRatio: "1920/300",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        // marginBottom: 
    }

    const mobile = windowSize.width < 900;
    return (
        <>
            <div style={spacerStyle} className="SpacerTop" />
            <div className='Testimonials'>
                {/* <Grid container>
                    <Grid item xs />
                    <Grid item xs={12} sm={9} lg={8} spacing={3} padding={10}>
                        <Typography variant="h3">
                            
                        </Typography>
                    </Grid>
                    <Grid item xs />
                </Grid> */}
                <Typography variant="h3" className='WhiteText'>
                    Words of Praise
                </Typography>
                <Grid container style={windowSize.width >= 900 ? { height: "100%" } : { height: "100%" }}>
                    <Grid item xs />
                    <Grid container item xs={11} sm={10} md={11} lg={10} margin={mobile ? 0 : 2} marginTop={2} columnSpacing={mobile ? 0 : 3} rowSpacing={3}>
                        {
                            cards.map((card, iCard) => {
                                const isBooped = booped === iCard;
                                return (
                                    <Grid key={iCard} item xs={12} mx={windowSize.width >= 900 ? 20 : 0}>
                                        <div className={windowSize.width >= 900 ? 'TestimonialCard' : 'TestimonialMobile'} onMouseLeave={() => { if (isBooped) { setBooped(-1) } }} onMouseEnter={() => { setBooped(iCard) }} style={{ overflow: "clip", minHeight: windowSize.width >= 900 ? "100%" : "1px" }} >
                                            {/* <div style={{
                                                width: "101%",
                                                margin: -1,
                                                transform: isBooped ? "scale(1.1)" : "scale(1)",
                                                transition: `transform ${200}ms`,
                                                aspectRatio: "16 / 9",
                                                backgroundImage: `url(${card.headshot})`,
                                                backgroundSize: "cover",
                                                overflow: "hidden"
                                            }}>
                                                <img
                                                    alt={card.name}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        transform: isBooped ? "scale(1.1)" : "scale(1)",
                                                        transition: `transform ${200}ms`,
                                                    }}
                                                    src={card.logo}
                                                />
                                            </div> */}
                                            <Avatar style={{ margin: 20, width: 200, height: 200 }}> <img style={{
                                                width: 200, height: 200, transform: isBooped ? "scale(1.1)" : "scale(1)",
                                                transition: `transform ${200}ms`
                                            }} src={card.headshot} /></Avatar>
                                            <div style={{ padding: "10px", overflow: "clip" }}>
                                                {/* <CardHeader avatar={ } /> */}
                                                <CardContent>
                                                    {/* <Avatar variant='square' sx={{ height: 70, width: 70, marginBottom: 1 }} /> */}
                                                    <img style={{ height: 40, margin: 10 }} src={card.logo} />
                                                    <Typography style={{ margin: 10 }} >
                                                        <i>{card.text}</i>
                                                    </Typography>
                                                    <Typography style={{ marginLeft: 40 }} variant="h6">{card.name}</Typography>
                                                </CardContent>
                                                {/* <Typography>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente facilis voluptatibus soluta, illo beatae enim sed sunt id tenetur facere, quidem vero, recusandae magnam voluptas tempora totam. Unde, doloremque ducimus?
                                                </Typography> */}
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs />
                </Grid>
            </div>
            <div className='SpacerBot' style={spacerStyle} />
        </>
    )
}

export default Testimonials