import { Typography, Toolbar, Button, ButtonGroup, useTheme, ButtonBase } from '@mui/material'
import React, { ReactElement, useRef } from 'react'
import AppBar from '../../components/AppBar'
import { useWindowSize } from '../../hooks/useWindowSize';
// import Button from '../../components/Button'
import './Main.css';
import Services from './Sections/Services/Services';
import Contact from './Sections/Contact/Contact';
import Home from './Sections/Home/Home';
import Approach from './Sections/Approach/Approach';
import Testimonials from './Sections/Testimonials/Testimonials';
import About from './Sections/About/About';
import { ReactComponent as MDLogo } from '../../assets/images/logos/md_il_yow.svg';
import { ReactComponent as PiziaLogo } from '../../assets/images/logos/Pizia_IL_R.svg';
import { ReactComponent as PiziaIso } from '../../assets/images/logos/Pizia_I_W.svg';
import { Facebook, Twitter, LinkedIn, Instagram } from "@mui/icons-material";

function Main() {

    const theme = useTheme();

    interface Section {
        name: string;
        showMenu: boolean;
        component: () => JSX.Element;
        ref: React.MutableRefObject<any>;
        blockToScroll: "start" | "center" | "end";
    }

    interface Social {
        name: string;
        color: string;
        link: string;
        icon: ReactElement;
    }

    const homeRef = useRef<null | HTMLDivElement>(null);
    const solutionsRef = useRef<null | HTMLDivElement>(null);
    const aboutRef = useRef<null | HTMLDivElement>(null);
    const visionRef = useRef<null | HTMLDivElement>(null);
    const testimonialsRef = useRef<null | HTMLDivElement>(null);
    const contactRef = useRef<null | HTMLDivElement>(null);

    const windowSize = useWindowSize();

    const mobile = windowSize.width < 900;

    const sections: Section[] = [
        {
            name: "Home",
            showMenu: false,
            component: Home,
            ref: homeRef,
            blockToScroll: "start",
        },
        {
            name: "Approach",
            showMenu: true,
            component: Approach,
            ref: solutionsRef,
            blockToScroll: "start",
        },
        {
            name: "Services",
            showMenu: true,
            component: Services,
            ref: aboutRef,
            blockToScroll: "start",
        },
        {
            name: "About",
            showMenu: true,
            component: About,
            ref: visionRef,
            blockToScroll: "start",
        },
        {
            name: "Praise",
            showMenu: true,
            component: Testimonials,
            ref: testimonialsRef,
            blockToScroll: "start",
        },
        {
            name: "Contact",
            showMenu: true,
            component: Contact,
            ref: contactRef,
            blockToScroll: "center",
        },
    ]

    const socials: Social[] = [
        // {
        //     name: "Twitter",
        //     color: "#1DA1F2",
        //     link: "https://twitter.com/PiziaStudios",
        //     icon: <Twitter />,
        // },
        {
            name: "LinkedIn",
            color: "#0077B5",
            link: "https://linkedin.com/company/pizia",
            icon: <LinkedIn />,
        },
        {
            name: "Facebook",
            color: "#4267B2",
            link: "https://facebook.com/PiziaStudios",
            icon: <Facebook />,
        },
        {
            name: "Instagram",
            color: "#E1306C",
            link: "https://instagram.com/PiziaStudios",
            icon: <Instagram />,
        },
        {
            name: "Pizia",
            color: "#dd413e",
            link: "https://pizia.com",
            icon: <PiziaIso style={{ color: "white", width: 20, height: 20 }} />,
        },
    ]

    return (
        <>
            <AppBar elevation={0} sx={{ position: "sticky", height: 70 }}>
                <Toolbar sx={{ height: 70 }} >
                    <div style={{ width: 220 }}>
                        <ButtonBase disableRipple onClick={() => { homeRef.current?.scrollIntoView({ behavior: "smooth", block: "end" }) }}>
                            <MDLogo
                                style={{ width: 100 }}
                            />
                        </ButtonBase>
                    </div>
                    <div style={{ flexGrow: 1, display: "flex", justifyContent: "center" }} >
                        {!mobile &&
                            <ButtonGroup>
                                {
                                    sections.filter(x => x.showMenu).map((section, iSection) => {
                                        const onClick = () => {
                                            section.ref.current.scrollIntoView({ behavior: "smooth", block: section.blockToScroll });
                                        }
                                        return (
                                            <Button size='large' onClick={onClick} key={iSection} sx={{ color: "black", borderRadius: 0, ":hover": { textDecoration: "2px underline black" } }} variant="text">{section.name}</Button>
                                        )
                                    })
                                }
                            </ButtonGroup>
                        }
                    </div>
                    <div style={{ width: 220, display: "flex", justifyContent: "flex-end" }}>
                        {mobile ?
                            <button onClick={() => { contactRef.current?.scrollIntoView({ behavior: "smooth", block: "start" }) }} className="MainButton" style={{ borderRadius: 50, height: 50, width: 150 }} >
                                Contact Us
                            </button>
                            :
                            socials.map((social, iSocial) => {
                                return (

                                    <button key={iSocial} onClick={() => { window.open(social.link, "_blank") }} className="MainButton" style={{ color: "white", marginLeft: 5, backgroundColor: social.color, borderRadius: 50, height: 45, width: 45 }} >
                                        {social.icon}
                                    </button>

                                )
                            })
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <div style={{ overflowX: "hidden" }}>
                {
                    sections.map((section, iSection) => {
                        return (
                            <div key={iSection} className='Section' ref={section.ref}>
                                <section.component />
                            </div>
                        )
                    })
                }
            </div>
            <div style={{ display: "flex", flexDirection: "row", overflow: "hidden", backgroundColor: "#efefef", marginTop: "-49px" }}>
                <div style={{ flexGrow: 1 }} />
                <a href="https://pizia.com/" style={{ color: "black", textDecoration: "none" }}>
                    <div style={{ background: "white", margin: "0px 10px 0px 10px", padding: "0px 30px 0px 30px", display: "flex", flexDirection: "row", border: "3px solid black", transform: "translate(0, 10px)", alignItems: "center", borderTopLeftRadius: 35, borderTopRightRadius: 35, height: 50 }}>
                        <Typography sx={{ marginBottom: 1, color: "black", textDecoration: "none", }}>
                            A service by
                        </Typography>
                        <div style={{ width: 10 }} />
                        <PiziaLogo style={{ height: 26 }} />
                    </div>
                </a>
                {mobile &&
                    <div style={{ flexGrow: 1 }} />
                }
            </div>
        </>
    )
}

export default Main