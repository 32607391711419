import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import './Home.css'
import img from '../../../../assets/images/illustrations/paginarender01.png';
import { useWindowSize } from '../../../../hooks/useWindowSize';

function Home() {
    const theme = useTheme();
    const windowSize = useWindowSize();
    const mobile = windowSize.width < 900;
    return (
        <div className='Home'>
            <Typography variant="h4">
                Unlock the
            </Typography>
            <Typography variant={"h2"} style={{ marginBottom: 3 }}>
                <span className='DecoratedText'>SPEND POTENTIAL</span>
            </Typography>
            <Typography variant="h4">
                of your game with our custom creatives
            </Typography>
            <img alt='image' style={{ display: mobile ? "none" : "block", maxWidth: "55vw" }} src={img} />
        </div>
    )
}

export default Home